<template>
  <v-card elevation="12" min-height="600">
    <v-card-title >
      Ricerca degli acquisti:
    </v-card-title>
    <br />
    <v-snackbar
      v-model="permSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
      :multi-line="true"
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <GlobalSearch
      v-show="!purchaseSelected"
      @searchError="showSearchError"
      @searchFound="searchFound"
    ></GlobalSearch>

  </v-card>
</template>

<script>
// @ is an alias to /src

import GlobalSearch from "../../components/purchases/GlobalSearch.vue";


export default {
  name: "PurchasesSearch",

  components: {
    GlobalSearch,
  },

  data: () => ({
    permSnackbar: false,
    snackbarColor: "success",
    errorText: "",
    timeout: -1,

    isSelectingRoom: false,
    roomSelected: null,
    purchaseSelected: null,
  }),

  methods: {

    showSearchError() {
      this.permSnackbar = true;
      this.snackbarColor = "error";
      this.errorText =
        "Ricerca fallita";
      this.timeout = 5000;
    },

    searchFound(result) {
      //console.log("Bene trovato ;)");
      //console.log(result);
      this.purchaseSelected = result;
    },
  },

  created: function () {},

  beforeDestroy: function () {},
};
</script>
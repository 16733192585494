var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-combobox',{attrs:{"items":_vm.items,"chips":"","clearable":"","label":"Digitare parole di ricerca libera","multiple":"","prepend-icon":"mdi-magnify","outlined":""},on:{"change":_vm.search},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":"","label":""},on:{"click":select,"click:close":function($event){return _vm.removeSearchItem(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))])])]}}]),model:{value:(_vm.chips),callback:function ($$v) {_vm.chips=$$v},expression:"chips"}}),_c('v-card-text',[(_vm.nOfRetrievedPurchases)?_c('span',[_vm._v("Trovati "+_vm._s(_vm.nOfRetrievedPurchases)+" acquisti")]):_vm._e(),(_vm.nOfRetrievedPurchases > 0)?_c('v-data-table',{attrs:{"elevation":"10","headers":_vm.headers,"items":_vm.retrievedPurchases,"item-key":"ID","search":_vm.subsearch,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        itemsPerPageText: 'Acquisti per pagina',
        itemsPerPageAllText: 'Tutti gli acquisti',
        itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
        pageText: '',
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filtra...","itemsPerPageText":"'Acquisti per pagina'"},model:{value:(_vm.subsearch),callback:function ($$v) {_vm.subsearch=$$v},expression:"subsearch"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('v-sheet',[_vm._v(" Nessun bene ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-sheet',[_vm._v(" Nessun bene ")])]},proxy:true},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.selectPurchase(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-arrow-right-bold-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Seleziona")])])]}},{key:"item.ID",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.closed ? 'text--disabled' : ''},[_vm._v(_vm._s(item.ID))])]}},{key:"item.description",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.closed ? 'text--disabled' : ''},[_vm._v(_vm._s(item.description))])]}},{key:"item.startedText",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.closed ? 'text--disabled' : ''},[_vm._v(_vm._s(item.startedText))])]}},{key:"item.users",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.closed ? 'text--disabled' : '',domProps:{"innerHTML":_vm._s(item.users)}})]}},{key:"item.stateText",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.closed ? 'text--disabled' : ''},[_vm._v(_vm._s(item.stateText))])]}},{key:"item.modifiedText",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.closed ? 'text--disabled' : ''},[_vm._v(_vm._s(item.modifiedText))])]}},{key:"item.competenzaText",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:item.closed ? 'text--disabled pa-1' : 'pa-1',style:('color: #fff; background-color: ' +
                      item.fsm.states[
                        item.histories[
                          item.histories.length - 1
                        ].state
                      ].meta.color)},[_vm._v(" "+_vm._s(item.fsm.states[ item.histories[ item.histories.length - 1 ].state ].meta.responsible)),(item.financialAssignee && item.fsm.states[
              item.histories[
                item.histories.length - 1
              ].state
            ].meta.financial)?_c('span',{class:item.closed ? 'text--disabled pa-1' : 'pa-1',style:('color: #fff; background-color: ' +
                        item.fsm.states[
                          item.histories[
                            item.histories.length - 1
                          ].state
                        ].meta.color)}):_vm._e()])]}},{key:"item.financialOperator",fn:function(ref){
                        var item = ref.item;
return [_c('span',{class:item.closed ? 'text--disabled' : ''},[_vm._v(_vm._s(item.financialOperator))])]}},{key:"item.started",fn:function(ref){
                        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","href":'/purchase/' + item.ID,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizza")])])]}}],null,false,4213030378)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
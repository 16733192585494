<template>
  <v-container>
    <v-combobox
      v-model="chips"
      :items="items"
      @change="search"
      chips
      clearable
      label="Digitare parole di ricerca libera"
      multiple
      prepend-icon="mdi-magnify"
      outlined
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          @click="select"
          @click:close="removeSearchItem(item)"
          label
        >
          <strong>{{ item }}</strong>
        </v-chip>
      </template>
    </v-combobox>

    <v-card-text>
      <span v-if="nOfRetrievedPurchases"
        >Trovati {{ nOfRetrievedPurchases }} acquisti</span
      >
      <v-data-table
        v-if="nOfRetrievedPurchases > 0"
        elevation="10"
        :headers="headers"
        :items="retrievedPurchases"
        item-key="ID"
        :search="subsearch"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          itemsPerPageText: 'Acquisti per pagina',
          itemsPerPageAllText: 'Tutti gli acquisti',
          itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
          pageText: '',
        }"
      >
        <template v-slot:top>
          <v-text-field
            v-model="subsearch"
            label="Filtra..."
            class="mx-4"
            itemsPerPageText="'Acquisti per pagina'"
          />
        </template>
        <template v-slot:no-data>
          <v-sheet> Nessun bene </v-sheet>
        </template>
        <template v-slot:no-results>
          <v-sheet> Nessun bene </v-sheet>
        </template>
        <template v-slot:item.id="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="selectPurchase(item)"
              >
                <v-icon large> mdi-arrow-right-bold-outline </v-icon>
              </v-btn>
            </template>
            <span>Seleziona</span>
          </v-tooltip>
        </template>
                  <template v-slot:item.ID="{ item }">
                    <span :class="item.closed ? 'text--disabled' : ''">{{
                      item.ID
                    }}</span>
                  </template>
                  <template v-slot:item.description="{ item }">
                    <span :class="item.closed ? 'text--disabled' : ''">{{
                      item.description
                    }}</span>
                  </template>
                  <template v-slot:item.startedText="{ item }">
                    <span :class="item.closed ? 'text--disabled' : ''">{{
                      item.startedText
                    }}</span>
                  </template>
                  <template v-slot:item.users="{ item }">
                    <span
                      :class="item.closed ? 'text--disabled' : ''"
                      v-html="item.users"
                    ></span>
                  </template>
                  <template v-slot:item.stateText="{ item }">
                    <span :class="item.closed ? 'text--disabled' : ''">{{
                      item.stateText
                    }}</span>
                  </template>
                  <template v-slot:item.modifiedText="{ item }">
                    <span :class="item.closed ? 'text--disabled' : ''">{{
                      item.modifiedText
                    }}</span>
                  </template>
                  <template v-slot:item.competenzaText="{ item }">
                    <div
                      :class="item.closed ? 'text--disabled pa-1' : 'pa-1'"
                      :style="
                        'color: #fff; background-color: ' +
                        item.fsm.states[
                          item.histories[
                            item.histories.length - 1
                          ].state
                        ].meta.color
                      "
                    >
                      {{
                        item.fsm.states[
                          item.histories[
                            item.histories.length - 1
                          ].state
                        ].meta.responsible
                      }}<span
                        v-if="item.financialAssignee && item.fsm.states[
                item.histories[
                  item.histories.length - 1
                ].state
              ].meta.financial"
                        :class="item.closed ? 'text--disabled pa-1' : 'pa-1'"
                        :style="
                          'color: #fff; background-color: ' +
                          item.fsm.states[
                            item.histories[
                              item.histories.length - 1
                            ].state
                          ].meta.color
                        "
                        >
                      </span>
                    </div>
                  </template>
                  <template v-slot:item.financialOperator="{ item }">
                    <span :class="item.closed ? 'text--disabled' : ''">{{
                      item.financialOperator
                    }}</span>
                  </template>
                  <template v-slot:item.started="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          small
                          v-bind="attrs"
                          v-on="on"
                          :href="'/purchase/' + item.ID" target="_blank"
                        >
                          <v-icon small> mdi-eye-outline </v-icon>
                        </v-btn>
                      </template>
                      <span>Visualizza</span>
                    </v-tooltip>
                  </template>
      </v-data-table>
    </v-card-text>
  </v-container>
</template>


<script>
const moment = require("moment-timezone");

export default {
  name: "GlobalSearch",

  data: () => ({
    chips: [],
    defaultfsm: null,
    // items: ["PC", "LAPTOP", "SERVER", "TABLET", "MONITOR", "PRINTER"],
    items: [],
    retrievedPurchases: [],
    nOfRetrievedPurchases: null,
    subsearch: "",

    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "ID",
      },
      {
        text: "Inizio",
        align: "start",
        sortable: true,
        value: "startedText",
      },
      {
        text: "Descrizione",
        align: "start",
        sortable: true,
        value: "description",
      },
      {
        text: "Utente/i",
        align: "start",
        sortable: true,
        value: "users",
      },
      {
        text: "Ultimo aggiornamento",
        align: "start",
        sortable: true,
        value: "modifiedText",
      },
      {
        text: "Stato",
        align: "start",
        sortable: true,
        value: "stateText",
        // value: "histories",
      },
      {
        text: "Competenza",
        align: "start",
        sortable: true,
        value: "competenzaText",
      },
      {
        text: "Operatore amministrativo",
        align: "start",
        sortable: true,
        value: "financialOperator",
      },
      {
        text: "",
        align: "end",
        sortable: false,
        value: "started",
      },
    ],
  }),

  methods: {
    search() {
      //console.log(this.chips);

      let searchString = "";
      for (let c of this.chips) {
        searchString += c + " ";
      }

      let body = {
        search: searchString,
      };

      if (searchString != "") {
        //console.log(searchObj);
        const headers = {
          authorization: "Bearer " + this.$store.state.authJwt,
        };
        this.$http
          .post(
            process.env.VUE_APP_PATRIMONIO_API + "/purchases/globalsearch",
            body,
            { headers }
          )
          .then((response) => {
            if (response.data.numPurchases > 0) {
              this.nOfRetrievedPurchases = response.data.numPurchases;
              
              for (let purchase of response.data.purchases) {
                purchase.fsm = this.defaultfsm;
                let UserText = "";
                let first = true;
                purchase.startedText = this.formatDateOnly(purchase.started);
                purchase.modifiedText = this.formatDate(
                  purchase.histories[
                    purchase.histories.length - 1
                  ].date
                );
                purchase.UserText = UserText;
                purchase.stateText =
                  purchase.fsm.states[
                    purchase.histories[
                      purchase.histories.length - 1
                    ].state
                  ].meta.fullStateName;
                purchase.competenzaText =
                  purchase.fsm.states[
                    purchase.histories[
                      purchase.histories.length - 1
                    ].state
                  ].meta.responsible;
                if (purchase.financialAssignee) {
                  purchase.financialOperator =
                    purchase.financialAssignee.COGNOME +
                    " " +
                    purchase.financialAssignee.NOME;
                }
              }
              this.retrievedPurchases = response.data.purchases;
            } else {
              // this.$emit("searchError");
              this.retrievedPurchases = [];
            }
          })
          .catch((error) => {
            // handle error
            console.log(error);
            this.$emit("searchError");
          });
      }
    },

    removeSearchItem(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
      // Reset the search
      this.nOfRetrievedPurchases = null;
      this.retrievedPurchases = null;
      this.search();
    },
    formatDate(date) {
      return moment(date)
        .tz("Europe/Rome")
        .locale("IT")
        .format("D MMMM YYYY, HH:mm");
    },
    formatDateOnly(date) {
      return moment(date).tz("Europe/Rome").locale("IT").format("D MMM YYYY");
    },
    selectPurchase(item) {
      this.$emit("searchFound", item);
    },
  },

  created: function () {
    // Fetch scanPurchases and their reports
    this.$http
      .get(process.env.VUE_APP_PATRIMONIO_API + "/purchases/defaultfsm", {
        headers: {
          authorization: "Bearer " + this.$store.state.authJwt,
        },
      })
      .then((response) => {
        this.defaultfsm = response.data.fsm;
        this.groupNotifyLabel =
          "Notifica " +
          this.defaultfsm.states[this.defaultfsm.initial].meta.responsibleGroup;

        this.selectedGroupUsers = [];
        this.$http
          .get(
            process.env.VUE_APP_PATRIMONIO_API +
              "/users/getgroupbyname/" +
              this.defaultfsm.states[this.defaultfsm.initial].meta.ownersGroup,
            {
              headers: {
                authorization: "Bearer " + this.$store.state.authJwt,
              },
            }
          )
          .then((response) => {
            this.currentGroupUsers = response.data.Users;
            for (let user of this.currentGroupUsers) {
              this.selectedGroupUsers.push({
                matricola: user.MATRICOLA,
                fullname: user.COGNOME + " " + user.NOME,
              });
            }
            this.search();
          })
          .catch((err) => {});
      })
      .catch((err) => {
        console.log(err);
      });
      

  },
};
</script>
